import CustomizerReducer from './customizer/CustomizerSlice';
import UserAuthReducer from './auth/UserAuthSlice.jsx';
import UserRoleReducer from './auth/userRoleSlice';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const reducer = combineReducers({
  customizer: CustomizerReducer,
  UserAuthReducer: UserAuthReducer,
  UserRoleReducer: UserRoleReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export default store;
