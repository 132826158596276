import { loginFailureMsg, logout } from './UserAuthSlice';
import store from '../Store';
import { flush } from './userRoleSlice';

let inactivityTimeout;

const resetTimer = () => {

  clearTimeout(inactivityTimeout);

  inactivityTimeout = setTimeout(() => {
    localStorage.removeItem('postLoginRedirect');
    localStorage.removeItem('avatarColor');
    store.dispatch(flush());
    store.dispatch(logout());
    store.dispatch(
      loginFailureMsg({
        message: 'You have been logged out due to exceeding the inactivity limit',
      }),
    );
  }, 3600000);
};

const activityHandler = () => {
  resetTimer();
};

const initializeInactivityCheck = () => {
  window.onload = resetTimer;
  window.addEventListener('mousemove', activityHandler);
  window.addEventListener('keypress', activityHandler);
  window.addEventListener('scroll', activityHandler);
};

export default initializeInactivityCheck;
