import axios from 'axios';

let tokenValue = null;

export const setToken = (token) => {
  tokenValue = token;
};

export const apiData = async (method, url, data) => {
  const options = await getOptionsConfig(method, url, data, tokenValue);

  return new Promise((resolve, reject) => {
    axios
      .request(options)
      .then((res) => {
        if (res && res.data) {
          resolve({
            statusCode: res.status,
            msg: res.data.msg,
            data: res.data.data || res.data,
            pagination: res.data.total_count,
          });
        }
      })
      .catch((ex) => {
        console.error('Request Error:', ex);
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
          resolve({
            statusCode: ex.response.status,
            error: ex.response.data,
            msg: ex.response.data?.msg,
          });
        } else {
          resolve({
            statusCode: 500,
            error: ex.message,
            msg: ex.response.data?.msg,
          });
        }
      });
  });
};

async function getOptionsConfig(method, url, data, token) {
  if (method === 'get') {
    return {
      method,
      url,
      params: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  } else if (method === 'post') {
    if (url.includes('signIn')) {
      return {
        method,
        url,
        data,
        headers: { 'Content-Type': 'application/json' },
      };
    } else {
      if (data instanceof FormData) {
        return {
          method,
          url,
          data,
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(token && { Authorization: `Bearer ${token}` }),
          },
        };
      } else {
        return {
          method,
          url,
          data,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
      }
    }
  } else {
    return {
      method,
      url,
      params: data,
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  }
}
